





























































import { Component } from "vue-property-decorator";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import VueJsonToCsv from "vue-json-to-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import DetailsRequest from "../../../src/components/DetailsRequest.vue";
import { HeaderItem } from "../../types/headeritem";
import { Breadcrumb } from "../../types/breadcrumb";

@Component({
  components: {
    List,
    BaseBreadcrumb,
    VueJsonToCsv,
    PageHeader,
    DetailsRequest,
  },
})
export default class ReportRequisition extends Request {
  title: string = `${translate("reportRequisition")}`;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("reportRequisition")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "detailsRequest",
      itemValue: "detailsRequest",
    },
  ];

  date: Date = new Date();
  formatDate: string =
    this.date.getDate() +
    "/" +
    (this.date.getMonth() + 1) +
    "/" +
    this.date.getFullYear();

  items = [];
  search: string = "";
  tableId: string = "audiTable";
  itemsDownload = [];

  header: HeaderItem[] = [
    { text: `${translate("method")}`, align: "start", value: "method" },
    {
      text: `${translate("service")}`,
      align: "center",
      value: "service",
    },
    { text: `${translate("callType")}`, align: "center", value: "callType" },
    {
      text: `${translate("httpResponse")}`,
      align: "center",
      value: "httpResponse",
    },
    {
      text: `${translate("detailsRequest")}`,
      align: "center",
      value: "detailsRequest",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-chart-bar-stacked",
    titlePage: `${translate("reportRequisition")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: any[] = [
    {
      method: "Get",
      service: "Request-returns",
      callType: "Requisição",
      httpResponse: 200,
      detailsRequest: [],
    },
    {
      method: "Post",
      service: "Request-returns",
      callType: "Requisição",
      httpResponse: 500,
      detailsRequest: [],
    },
    {
      method: "Get",
      service: "Request-returns",
      callType: "Requisição",
      httpResponse: 200,
      detailsRequest: [],
    },
  ];

  createPDF() {
    var pdf = new jsPDF("p", "mm", "a4");
    var element = document.getElementById(this.tableId);

    html2canvas(element).then((canvas) => {
      var image = canvas.toDataURL("image/png");
      pdf.addImage(
        image,
        "JPEG",
        1,
        1,
        canvas.width * 0.12,
        canvas.height * 0.12,
        "a",
        "FAST"
      );
      pdf.save("dados_exportados" + ".pdf");
    });
  }
  downloadCsv(list) {
    return list;
  }

  async downloadExcel(listToDownload) {
    this.itemsDownload = await this.downloadCsv(listToDownload);
    let result, ctr, keys;

    if (
      this.itemsDownload === null ||
      (!this.itemsDownload && !this.itemsDownload.length)
    ) {
      return this.$message?.successMessage("Download Pronto");
    }

    keys = Object.keys(this.itemsDownload[0]);

    result = "";
    result += keys.join(",");
    result += "\n";

    this.itemsDownload.forEach((item) => {
      ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) {
          result += ",";
        }

        result +=
          typeof item[key] === "string" && item[key].includes(",")
            ? `"${item[key]}"`
            : item[key];
        ctr++;
      });
      result += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = "relatório.csv";
    hiddenElement.click();
  }
}
