




















































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";

import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import QueueInput from "./QueueInput.vue";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import AttendencyQueue from "./AttendencyQueue.vue";
import { translate } from "../../plugins/i18n/index";
import { format, parseISO } from "date-fns";
import { mapGetters } from "vuex";
import DeleteQueueWithAttendency from "../../components/DeleteQueueWithAttendency.vue";
@Component({
  components: {
    List,
    QueueInput,
    AttendencyQueue,
    BaseBreadcrumb,
    ConfirmDelete,
    DeleteQueueWithAttendency,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class QueueList extends Request {
  deletePath: string = "";
  title = `${translate("atendencyQueue")}`;
  breadcrumbs = [
    {
      text: `${translate("atendencyQueue")}`,
      disabled: true,
    },
  ];

  header = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    {
      text: `${translate("registerDate")}`,
      align: "center",
      value: "createdAt",
    },
    { text: `${translate("active")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  flowList: any = [];
  timeGroupList: any = [];
  companyId: number = null;
  userList: any = [];
  user: any = {};
  pageHeader: TPageHeader = {
    mainIcon: "mdi-human-queue",
    titlePage: `${translate("atendencyQueue")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  getUser: any;
  list = [];
  async loadData() {
    this.companyId = this.getUser().companyId;
    this.user = this.getUser();
    this.deletePath = this.paths.queueDeletePath;
    const result = await this.get(this.paths.queuePath);

    result[0].map((product) => {
      product.createdAt = format(parseISO(product.createdAt), "dd/MM/yyyy");
      product.price = "R$ " + product.price;
    });
    this.list = result[0];
    const user = await this.get(
      this.paths.userByCompanyPath + `?companyId=${this.companyId}`
    );
    this.userList = user[0];
    const flow = await this.get(this.paths.flowPath);
    this.flowList = flow[0];
    const calendar = await this.get(this.paths.calendarPath);
    this.timeGroupList = calendar[0];
  }
  async mounted() {
    this.loadData();
  }
}
