






















































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import InputPopup from "@matrixbrasil/matrix-lib-crud-frontend/src/views/layout/InputPopup.vue";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import ConfirmDelete from '../../components/ConfirmDelete.vue'
import { mapGetters } from "vuex";
@Component({
  components: {
    InputPopup,
    ConfirmDelete,
  },
    methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class AttendencyQueue extends Request {
  @Prop() readonly idQueue: number;
  deletePath: string = "";
  userId = 0;
  itemsForm: InputModel[] = [];
  userList: any[] = [];
  dialog = false;
  list: any = [];
  newAgent: any = [];
    getUser: any;
  editorUserId: number = null;
  get unselectAgent() {
    return this.list.filter((u) => {
      const user = this.newAgent.find((a) => a.userId == u.userId);
      return !user;
    });
  }
  async addComponent() {
    const user = this.userList.find((a) => a.id == this.userId);
    const queueUser = await this.save(this.paths.categoryPath, {
      userId: this.userId,
      queueId: this.idQueue,
    });

    this.newAgent.push({ ...queueUser, name: user.name });
  }

  listAgent: any = [];
  async mounted()  {
       const user = this.getUser();
    this.editorUserId = user.id;
    this.deletePath = this.paths.categoryPath;
    this.listAgent = await this.get(this.paths.userAgentPath, { companyId: 1 });
    this.userList = await this.get(this.paths.userPath, { companyId: 1 });
    const userListQueue = await this.get(this.paths.categoryPath, {
      queueId: this.idQueue,
    });
    if (userListQueue) {
      this.newAgent = userListQueue.map((u) => {
        const user = this.userList.find((a) => a.id == u.userId);
        return {
          name: user ? user.name : "",
          ...u,
        };
      });
    }

    for (const agent of this.listAgent) {
      const user = this.userList.find((a) => a.id == agent.userId);
      if (user) {
        if (agent.type == "A") {
          agent.isAgent = true;
        } else {
          agent.isAgent = false;
        }
        this.list.push({ ...agent, name: user.name });
      }
    }
  }
}
