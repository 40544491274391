


































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";

@Component
export default class Nps extends Request {
  @Prop() localEntity: any;
  @Prop() editorUserId: any;
  @Prop() loadData: any;
  entityToSave: any = {};

  load(): void {
    if (this.localEntity) {
      this.entityToSave = { ...this.localEntity };
    }
  }

  async onSave()  {
    this.localEntity.editorUserId = this.editorUserId;
    let saveResearch: any = {
      researchName: this.entityToSave.researchName,
      researchType: this.entityToSave.researchType,
      finalMessage: this.entityToSave.finalMessage,
      timeoutMessage: this.entityToSave.timeoutMessage,
      waitingTime: this.entityToSave.waitingTime,
      editorUserId: this.editorUserId,
      questions: [],
    };

    saveResearch.questions = [
      {
        questionType: "C",
        text: this.entityToSave.question,
        answers: [
          {
            correctAnswer: false,
            answerText: "0",
          },
          {
            correctAnswer: false,
            answerText: "1",
          },
          {
            correctAnswer: false,
            answerText: "2",
          },
          {
            correctAnswer: false,
            answerText: "3",
          },
          {
            correctAnswer: false,
            answerText: "4",
          },
          {
            correctAnswer: false,
            answerText: "5",
          },
          {
            correctAnswer: false,
            answerText: "6",
          },
          {
            correctAnswer: false,
            answerText: "7",
          },
          {
            correctAnswer: false,
            answerText: "8",
          },
          {
            correctAnswer: false,
            answerText: "9",
          },
          {
            correctAnswer: false,
            answerText: "10",
          },
        ],
      },
    ];
    const result = await this.save(this.paths.pathResearchFull, saveResearch);
    this.loadData();
    this.$emit("update-dialog", false);

    return result;
  }
  mounted() {
    this.load();
  }
}
