







































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { Satisfaction } from "../../types/SatisfactionSurvey";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import SatisfactionSurveyInput from "./SatisfactionSurveyInput.vue";
import { translate } from "../../plugins/i18n/index";
import ConfirmDelete from "../../components/ConfirmDelete.vue";

@Component({
  components: {
    List,
    SatisfactionSurveyInput,
    BaseBreadcrumb,
    ConfirmDelete,
  },
})
export default class AssetTriggerList extends Request {
  deletePath: string = "";
  title = `${translate("satisfactionSurvey")}`;
  breadcrumbs = [
    {
      text: `${translate("satisfactionSurvey")}`,
      disabled: true,
    },
  ];

  tagStatus = "status";
  date = new Date();
  formatDate =
    this.date.getDate() +
    "/" +
    (this.date.getMonth() + 1) +
    "/" +
    this.date.getFullYear();

  header = [
    {
      text: `${translate("researchName")}`,
      align: "start",
      value: "researchName",
    },
    //   {
    //     text: `${translate("creationDate")}`,
    //     align: "start",
    //     value: "createdAt",
    //   },
    {
      text: `${translate("questionType")}`,
      align: "start",
      value: "researchType",
    },
    {
      text: `${translate("finalMessage")}`,
      align: "center",
      value: "finalMessage",
    },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-spellcheck",
    titlePage: `${translate("satisfactionSurvey")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Satisfaction[] = [];

  async loadData() {
    this.deletePath = this.paths.pathResearch;
    const result = await this.get(this.paths.pathResearch);
    this.list = result[0];
  }
  async mounted()  {
    this.loadData();
  }
}
