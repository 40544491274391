


















































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
import NPS from "./Nps.vue";
import CSAT from "./Csat.vue";
@Component({
  components: {
    NPS,
    CSAT,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class SatisfactionSurveyInput extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly list: any[];
  @Prop() loadData: any;
  readonly header = `${translate("Satisfaction")}`;
  questionTypeList: any = [
    { id: 1, name: `${translate("NPS")}` },
    { id: 2, name: `${translate("CSAT")}` },
  ];

  questionType: string;
  dialog: boolean = false;
  localEntity: any = {};
  getUser: any;
  questions: any = [{ text: "", text2: "" }];
  editorUserId: number = null;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
  }
  updateDialog(value) {
    this.dialog = value;
  }
}
