










































































































import { Component, Prop } from "vue-property-decorator";
import { IProduct } from "../../types/Product";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { format, parseISO } from "date-fns";

@Component({
  components: {},
})
export default class ProductInput extends Request {
  @Prop() readonly entity: IProduct;
  @Prop() readonly list: IProduct[];
  readonly header = translate("product");

  dialog: boolean = false;
  localEntity: any = {};

  active: any = null;
  name: string = "";
  description: string | undefined = "";
  scope: string = "";
  logo: string | undefined = "";
  token: string = "";

  async onSave()  {
    this.dialog = false;
    return await this.newList();
  }

  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }

  async newList() {
    Object.assign(this.entity, this.localEntity);
    this.entity.name = this.name;
    this.entity.description = this.description;
    this.entity.active = this.active;
    this.entity.scope = this.scope;
    this.entity.token = this.token;
    this.entity.logo = this.logo;

    const result = await this.save(this.paths.productPath, this.entity);
    result.updatedAt = format(parseISO(result.updatedAt), "dd/MM/yyyy");
    const dbEntity = result;
    if (!this.localEntity.id) {
      this.list.push({
        ...this.entity,
        id: dbEntity.id,
        name: dbEntity.name,
        description: dbEntity.description,
        active: dbEntity.active,
        scope: dbEntity.scope,
        logo: dbEntity.logo,
        token: dbEntity.token,
        updatedAt: dbEntity.updatedAt,
      });
      this.localEntity = {};
    }
  }

  async mounted() {
    this.name = this.entity.name;
    this.description = this.entity.description;
    this.active = this.entity.active;
    this.scope = this.entity.scope;
    this.token = this.entity.token;
    this.logo = this.entity.logo;
    this.load();
  }

  get missingName() {
    return !this.name;
  }
  get missingToken() {
    return !this.token;
  }
  get saveDisabled() {
    return this.missingName || this.missingToken;
  }
}
