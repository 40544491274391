













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import {
  Action,
  ProfileFunctionalityAction,
} from "../../types/profile/ProfileFunctionalityAction";

@Component({
  components: {},
})
export default class ProfileActions extends Request {
  selected: any = [];
  selectedAll: any = [];
  @Prop() actionList: Action[];
  @Prop() companyId: number | string;
  @Prop() functionalities: ProfileFunctionalityAction[];
  @Prop() profileId: number;

  isChecked(funcId) {
    this.selected = this.selected.filter((e) => {
      e != funcId;
      return this.$emit("selected-actions", this.selected);
    });
  }

  async load() {
    const result = await this.get(this.paths.profilePath, {
      companyId: this.companyId,
    });

    const checkboxes = result[0].find(
      (i) => i.id === this.profileId
    ).profileFunctionalityActions;
    checkboxes.map((item) => {
      return this.selected.push([item.functionalityId, item.actionId]);
    });
  }

  async mounted() {
    if (this.profileId) {
      this.load();
    }
  }
  checkAll(funcId) {
    this.selected = this.selected.filter((e) => {
      return e[0] != funcId;
    });
    if (this.selectedAll.includes(funcId)) {
      for (let index = 0; index < this.functionalities.length; index++) {
        if (this.actionList[index]) {
          this.selected.push([funcId, this.actionList[index].id]);
        }
      }
    }
    return (
      this.$emit("selected-actions", this.selected) &&
      this.$emit("selected-functions", this.selectedAll)
    );
  }
}
