

























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";

import { translate } from "../../plugins/i18n/index";
@Component
export default class Csat extends Request {
  @Prop() localEntity: any;
  @Prop() editorUserId: any;
  @Prop() loadData: any;
  entityToSave: any = {};
  questions: any = [];
  typeList: any = [
    { id: 1, name: translate("close"), value: "C" },
    { id: 2, name: translate("open"), value: "O" },
  ];
  type: string = "";
  load(): void {
    if (this.localEntity) {
      this.entityToSave = { ...this.localEntity };
    }
  }

  async onSave()  {
    this.localEntity.editorUserId = this.editorUserId;
    let saveResearch: any = {
      id: this.entityToSave.id ? this.entityToSave.id : null,
      researchName: this.entityToSave.researchName,
      researchType: this.entityToSave.researchType,
      finalMessage: this.entityToSave.finalMessage,
      timeoutMessage: this.entityToSave.timeoutMessage,
      waitingTime: this.entityToSave.waitingTime,
      editorUserId: this.editorUserId,
      questions: [],
    };

    saveResearch.questions = this.questions;
    const result = await this.save(this.paths.pathResearchFull, saveResearch);
    this.loadData();
    this.$emit("update-dialog", false);

    return result;
  }
  mounted() {
    this.load();
    if (!this.entityToSave.id) {
      this.questions.push({
        answers: [
          {
            correctAnswer: false,
            answerText:
              `${translate("Very")}` + " " + `${translate("Unsatisfied")}`,
          },
          {
            correctAnswer: false,
            answerText:
              `${translate("Little")}` + " " + `${translate("Unsatisfied")}`,
          },
          {
            correctAnswer: false,
            answerText: `${translate("Neutral")}`,
          },
          {
            correctAnswer: false,
            answerText:
              `${translate("Little")}` + " " + `${translate("Satisfied")}`,
          },
          {
            correctAnswer: false,
            answerText:
              `${translate("Very")}` + " " + `${translate("Satisfied")}`,
          },
        ],
      });
    } else {
      this.questions = this.entityToSave.questions;
    }
  }
  addComponent(): void {
    this.questions.push({
      answers: [
        {
          correctAnswer: false,
          answerText:
            `${translate("Very")}` + " " + `${translate("Unsatisfied")}`,
        },
        {
          correctAnswer: false,
          answerText:
            `${translate("Little")}` + " " + `${translate("Unsatisfied")}`,
        },
        {
          correctAnswer: false,
          answerText: `${translate("Neutral")}`,
        },
        {
          correctAnswer: false,
          answerText:
            `${translate("Little")}` + " " + `${translate("Satisfied")}`,
        },
        {
          correctAnswer: false,
          answerText:
            `${translate("Very")}` + " " + `${translate("Satisfied")}`,
        },
      ],
    });
  }
}
