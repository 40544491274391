




























import { Component, Prop } from "vue-property-decorator";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { IProfile } from "../../types/profile/ProfileType";
import Request from "../../services/request";
import ProfileActions from "./ProfileActions.vue";
import {
  Action,
  ProfileFunctionalityAction,
} from "../../types/profile/ProfileFunctionalityAction";
import { translate } from "../../plugins/i18n/index";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

import { mapGetters } from "vuex";
import {IUser} from "../../types/User";
@Component({
  components: {
    ProfileActions,
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ProfileInput extends Request {
  @Prop() readonly entity: IProfile;
  @Prop() readonly list: IProfile[];
  @Prop() profileId: number;
  @Prop() companyId: number;

  @Prop() loadData: () => void;

  header = `${translate("title.addProfile")}`;
  getUser!: () => IUser;
  editorUserId: number = null;
  active: boolean = true;
  actionList: Action[] = [];
  functionalityList: ProfileFunctionalityAction[] = [];

  filterFuncionalities: any[] = [];
  selectedActions(value) {
    const objs = value.map(function (x) {
      return {
        actionId: x[1],
        functionalityId: x[0],
      };
    });
    this.filterFuncionalities = objs;
  }
  async onSave() {
    this.entity.companyId = this.companyId;
    this.entity.profileFunctionalityActions = this.filterFuncionalities;
    this.entity.active = this.active;
    this.entity.editorUserId = this.editorUserId;
    if (this.entity.id) {
      this.entity.profileId = this.entity.id;

      const result = await this.put(this.paths.profileUpdatePath, this.entity);

      return result;
    } else {
      const result = await this.save(this.paths.profileSavePath, this.entity);
      return result;
    }
  }
  async created() {
    const actionList = await this.get(this.paths.actionPath);
    this.actionList = actionList[0];

    const result = await this.get(this.paths.functionalityPath);

    this.functionalityList = result[0];
    const user = this.getUser();
    this.editorUserId = user.id;
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 6,
      label: `${translate("name")}`,
      tag: "v-text-field",
      type: "text",
      model: "name",
      required: true,
    },
    {
      id: 3,
      col: 1,
      slot: "active",
    },
    {
      id: 4,
      col: 12,
      slot: "profileActions",
      required: true,
    },
  ];
}
