

















import { Component, Vue } from "vue-property-decorator";
import Barchart from "./charts/BarChart.vue";
import PieChart from "./charts/PieChart.vue";
import LineChart from "./charts/LineChart.vue";
import LineChartGradient from "./charts/LineChartGradient.vue";
@Component({
  components: {
    Barchart,
    PieChart,
    LineChart,
    LineChartGradient,
  },
})
export default class List extends Vue {
  randonColor(item: number) {
    if (item > 70) return "red";
    else if (item > 50) return "orange";
    else return "green";
  }
}
