


































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import ProfileInput from "./ProfileInput.vue";
import { IProfile } from "../../types/profile/ProfileType";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import {IUser} from "../../types/User";
import { Breadcrumb } from "../../types/breadcrumb";
import { HeaderItem } from "../../types/headeritem";
@Component({
  components: {
    ProfileInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ProfileList extends Request {
  companyId: number = null;
  deletePath: string = "";
  title = `${translate("title.profile")}`;
  getUser!: () => IUser;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      to: "company-list",
      disabled: false,
    },
    {
      text: `${translate("title.profile")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] =[
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("active")}`, align: "start", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-folder-account-outline",
    titlePage: `${translate("profile")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IProfile[] = [];
  async loadData() {
    const user = this.getUser();
    this.companyId = user.companyId;
    this.deletePath = this.paths.profilePath;
    const result = await this.get(this.paths.profilePath, {
      companyId: this.companyId,
    });
    if (result) {
      this.list = result[0];
    }
  }
  async mounted() {
    this.loadData();
  }
}
