var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":_vm.$t('edit')}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":_vm.$t('create')}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card',[_c('v-card-text',[_c('v-tabs',{staticClass:"mb-5",attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.items),function(item){return _c('v-tab',{key:item,class:_vm.nextDisabled ? 'disabled-slider' : null},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-tabs-slider',{attrs:{"color":"primary"}})],2),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('name'),"placeholder":_vm.$t('name'),"outlined":""},model:{value:(_vm.localEntity.name),callback:function ($$v) {_vm.$set(_vm.localEntity, "name", $$v)},expression:"localEntity.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.timeGroupList,"label":_vm.$t('timeGroup'),"item-text":"name","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.localEntity.selectedTime),callback:function ($$v) {_vm.$set(_vm.localEntity, "selectedTime", $$v)},expression:"localEntity.selectedTime"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.flowList,"label":_vm.$t('flow'),"item-text":"name","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.localEntity.flowId),callback:function ($$v) {_vm.$set(_vm.localEntity, "flowId", $$v)},expression:"localEntity.flowId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('closingTime'),"placeholder":_vm.$t('closingTime'),"outlined":"","type":"number"},model:{value:(_vm.localEntity.inactiveTime),callback:function ($$v) {_vm.$set(_vm.localEntity, "inactiveTime", $$v)},expression:"localEntity.inactiveTime"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('closingMessage'),"placeholder":_vm.$t('closingMessage'),"outlined":""},model:{value:(_vm.localEntity.endMessage),callback:function ($$v) {_vm.$set(_vm.localEntity, "endMessage", $$v)},expression:"localEntity.endMessage"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info","disabled":_vm.nextDisabled},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])],1)],1)],1)],1),_c('v-tab-item',[_vm._l((_vm.queueUserAgents),function(item,index){return _c('v-row',{key:index,staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.userList,"label":_vm.$t('user'),"item-text":"firstName","item-value":"id","return-object":false,"item-disabled":_vm.disableItem},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}],null,true),model:{value:(_vm.queueUserAgents[index].userAgentId),callback:function ($$v) {_vm.$set(_vm.queueUserAgents[index], "userAgentId", $$v)},expression:"queueUserAgents[index].userAgentId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.priorityList,"label":_vm.$t('priority'),"item-text":"id","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}}],null,true),model:{value:(_vm.queueUserAgents[index].priority),callback:function ($$v) {_vm.$set(_vm.queueUserAgents[index], "priority", $$v)},expression:"queueUserAgents[index].priority"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('serviceLimit'),"outlined":"","type":"number"},model:{value:(_vm.queueUserAgents[index].attendencyLimit),callback:function ($$v) {_vm.$set(_vm.queueUserAgents[index], "attendencyLimit", $$v)},expression:"queueUserAgents[index].attendencyLimit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.activeList,"label":_vm.$t('active'),"item-text":"title","item-value":"value","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}],null,true),model:{value:(_vm.queueUserAgents[index].active),callback:function ($$v) {_vm.$set(_vm.queueUserAgents[index], "active", $$v)},expression:"queueUserAgents[index].active"}})],1)],1)}),_c('v-col',{staticClass:"btn",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"dotted-button",attrs:{"block":"","text":""},on:{"click":_vm.addComponent}},[_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"justify-end flex-wrap"},[_c('v-btn',{staticClass:"mr-2 text-capitalize mb-5",attrs:{"text":"","dark":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize mb-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize mb-5",attrs:{"color":"info"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }