











































































































































































































































import { Component, Prop } from "vue-property-decorator";
import InputPopup from "@matrixbrasil/matrix-lib-crud-frontend/src/views/layout/InputPopup.vue";
import { Queue } from "../../types/Queue";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";

@Component({
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class QueueInput extends Request {
  @Prop() readonly entity: Queue;
  @Prop() readonly list: Queue[];
  @Prop() loadData: any;
  @Prop() userList: any;
  @Prop() timeGroupList: any;
  @Prop() flowList: any;
  localEntity: any = {};
  tab: number = null;
  companyId: number;
  getUser: any;
  dialog: boolean = false;
  priorityList: any = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
  ];
  activeList: any = [
    { id: 1, title: translate("active"), value: "A" },
    { id: 2, title: translate("inactive"), value: "I" },
  ];
  editorUserId: number = null;
  header = `${translate("atendencyQueue")}`;
  items = [`${translate("title.configuration")}`, `${translate("user")}`];
  queueUserAgents: any = [];

  async onSave()  {
    this.localEntity.companyId = this.companyId;
    this.localEntity.hourGroupId = 1;
    this.localEntity.queueUserAgents = this.queueUserAgents;
    this.localEntity.editorUserId = this.editorUserId;
    this.dialog = false;
    console.log(this.localEntity);
    const result = await this.post(this.paths.queueSavePath, this.localEntity);
    this.loadData();

    if (!this.localEntity.id) {
      this.localEntity = {};
    }
    this.tab = 0;
    return result;
  }
  addComponent(): void {
    this.queueUserAgents.push({});
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async mounted() {
    this.load();
    if (this.localEntity.queueUserAgents) {
      this.queueUserAgents = this.localEntity.queueUserAgents;
    }
    this.localEntity.selectedTime = this.localEntity.hourGroupId;
    this.companyId = this.getUser().companyId;
    this.editorUserId = this.getUser().id;
  }
  disableItem(item) {
    const isSelected = this.queueUserAgents.find((x) => x.userId === item.id);
    if (isSelected) return true;
    if (item.active === true) {
      return false;
    } else {
      return true;
    }
  }
  get missingName() {
    return !this.localEntity.name;
  }
  get nextDisabled() {
    return this.missingName;
  }
}
